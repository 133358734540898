import React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "@/components/Layout";
import Plate from "@/components/Plate";
import Hero from "@/components/Hero";

import { Text, Image, Flex, Box, Container } from "theme-ui";

export interface HomeProps {
  data: any;
}

const docs = [
  {
    name: "Dr Abdurahman Ambadi",
    bio: "Chairman and Consultant in General and Minimally Invasive Surgery",
  },
  {
    name: "Dr Hafiz Muhammad",
    bio: "Vice-Chairman and Consultant in Plastic, Reconstructive Cosmetic Surgery",
  },
  {
    name: "Dr C C Suresh",
    bio: "Patron and Chief Consultant in General and Minimally Invasive Surgery",
  },
  {
    name: "Dr Aneez K Arakkal",
    bio: "Medical Director",
  },
];

const Home: React.FC<HomeProps> = () => (
  <Layout>
    {/* <Hero /> */}
    <Plate variant="styles.contentFrame">
      <Container sx={{ pt: 7, pb: 5 }}>
        <Plate variant="styles.bodyFrame">
          <Text as="h1" variant="pageTitle">
            About Us
          </Text>
          <Text as="p" variant="sectionBody">
            CCS is the pioneer speciality referral surgery centre in Kerala. Located in the heart of
            Calicut, we are easily accessible to patients and can handle complicated emergency cases
            with utmost professionalism and care. We offer the most patient-friendly surgical care and
            contribute to the growth of healthcare in the state. With state-of-the-art infrastructure,
            expert surgeons, qualified staffs and world-class facilities we are setting a precedent in
            surgical care in Kerala and beyond.
          </Text>
        </Plate>
        <Plate variant="styles.bodyFrame">
          <Text as="h2" variant="sectionTitle" sx={{ mb: 4, fontSize: 5, fontWeight: 100, color: 'primary' }}>
            Why Choose Calicut Centre for Surgery?
          </Text>
          <Text as="p" variant="sectionBody">
            CCS is the first-ever healthcare facility exclusively set up for referral surgeries in the state.
            Our best-in-class facilities, patient-centred approach and the service of expert surgeons
            ensure an unparalleled treatment experience. At CCG, patients receive a customised
            treatment schedule and environment that suit their convenience and speedy recovery. We
            have a dedicated team of qualified nurses, caregivers and technicians that work in tandem
            with the expert surgeons to make the patient and family feel at ease throughout the
            treatment process. CCG offers a futuristic alternative that makes economical healthcare of
            supreme quality accessible to everyone.
          </Text>
        </Plate>
        <Plate variant="styles.bodyFrame">

          <Text as="h2" variant="sectionTitle" sx={{ mb: 4, fontSize: 5, fontWeight: 100, color: 'primary' }}>
            Our Team
          </Text>

          {docs.map((dep: any) => (
            <Flex
              sx={{ p: 3, pl: 0, mb: 3, flexWrap: "wrap", maxWidth: "45%" }}
            >
              {/* <Image src={`https://i.pravatar.cc/150?img=4`} width="140px" /> */}
              <Box sx={{ width: '100%' }}>
                <Text as="h3" variant="blockTitle" sx={{ mb: 1, fontSize: 2 }}>
                  {dep.name}
                </Text>
                <Text as="p" variant="sectionBody" sx={{ fontSize: 2 }}>
                  {dep.bio}
                </Text>
              </Box>
            </Flex>
          ))}
        </Plate>
      </Container>
    </Plate>
  </Layout>
);

export default Home;
